.label {
  color: var(--input-color);
  font-size: var(--font-size-sm);
  margin-bottom: var(--spacing-stack-6xs);  margin-bottom: var(--spacing-stack-6xs);
}

.checkbox,
.radiobutton {
  margin-left: var(--spacing-inline-xs);
  font-size: var(--font-size-base);
}

.toggle {
  margin-left: var(--spacing-inline-3xs);
  font-size: var(--font-size-base);
}