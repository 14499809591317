.shellSelect {
  position: relative;
  border: inherit;
}

.error {
  border-color: var(--color-status-critical-base) !important;
}

.select {
  text-align: left;
  border-radius: var(--border-radius-sm);
  border-width: var(--border-width-small);
  border-color: var(--input-border-color);
  border-style: solid;
  padding: var(--spacing-inset-md);
  font-size: var(--font-size-base);
  background-color: var(--select-bg);
  color: var(--select-color);
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: fit-content;
}

.iconOfSelect {
  display: flex;
  align-items: center;
  margin-left: var(--spacing-inset-xs);
}

.select:hover:not([disabled]) {
  box-shadow: 0px 3px 6px #00000029;
}

.select:focus:not([disabled]) {
  border-color: var(--color-neutral-dark-1);
}

.select[disabled] {
  color: var(--color-neutral-light-1);
  background-color: var(--color-neutral-light-3);
  border-color: var(--color-neutral-light-3);
  cursor: auto;
}

.iconInsideLeft {
  padding-left: 50px;
}

.iconInsideRight {
  padding-right: 50px;
}

.shellSelectItems {
  box-shadow: var(--shadow-level-2) rgba(0, 0, 0, 0.161);
  border-radius: var(--border-radius-sm);
  margin-top: var(--spacing-inset-xs);
  padding: 0;
  transition: 0.3s;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;
  position: absolute;
  z-index: 1;
  background: white;
  width: 100%;
  border: none;
  outline: none;
  list-style-type: none;
  max-height: 200px;
  overflow: auto;
}

.shellSelectItemsEnterActive {
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;
}

.shellSelectItemsEnterDone {
  opacity: 1;
  transform: scaleY(1);
  transform-origin: top;
}

.shellSelectItemsEnterExitActive {
  opacity: 1;
  transform: scaleY(1);
  transform-origin: top;
}

.shellSelectItemsDrawerExitDone {
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;
}

.selectItem {
  cursor: pointer;
  padding: var(--spacing-inset-2xl) var(--spacing-inset-lg);
  color: var(--color-neutral-dark-1);
}

.selectItemActive {
  background: var(--color-neutral-light-3);
}

.selectItemActive:first-child {
  background: var(--color-neutral-light-3);
  border-radius: var(--border-radius-sm) var(--border-radius-sm) 0 0;
}

.selectItemActive:last-child {
  background: var(--color-neutral-light-3);
  border-radius: 0 0 var(--border-radius-sm) var(--border-radius-sm);
}

.selectItem:focus {
  border: none;
  outline: none;
  background: var(--color-neutral-light-3);
}

.selectItem:hover {
  background: var(--color-neutral-light-3);
}

.selectItem:first-child:hover {
  background: var(--color-neutral-light-3);
  border-radius: var(--border-radius-sm) var(--border-radius-sm) 0 0;
}

.selectItem:last-child:hover {
  background: var(--color-neutral-light-3);
  border-radius: 0 0 var(--border-radius-sm) var(--border-radius-sm);
}

.selectItem:only-child:hover {
  background: var(--color-neutral-light-3);
  border-radius: var(--border-radius-sm);
}

.placeholder {
  color: var(--color-neutral-dark-4);
}
