.Tabs {
  width: 100%;
  color: var(--color-brand-secondary-base);
  border-radius: 2rem;

  @media (max-width: 769px) {
    padding: 2rem 0;
  }
}

ul.nav {
  width: 100%;
  margin: 0 auto 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid var(--color-neutral-light-3);
  @media (max-width: 768px) {
    width: 90%;
  }
}

ul.nav li {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  width: 50%;
  padding: 1rem;
  list-style: none;
  text-align: center;
  cursor: pointer;
}

ul.nav li:hover {
  border-bottom: 2px solid var(--color-brand-secondary-base);
  margin-bottom: -1px;
  color: var(--color-brand-secondary-base);
  font-weight: bold;
}

ul.nav li.active {
  border-bottom: 2px solid var(--color-brand-secondary-base);
  margin-bottom: -1px;
  color: var(--color-brand-secondary-base);
  font-weight: bold;
}
