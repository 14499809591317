#devices-search {
    display: flex;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
}

.device-info {
    min-width: 450px;
}

.device-info .itb-button-container {
    padding: 0;
}