.body {
    padding: var(--spacing-stack-xl) var(--spacing-stack-5xl);
    min-height: 100%;
    background-color: var(--color-neutral-light-4);
    display: flex;
    flex-direction: column;
    text-align: left;
}

.cardBody {
    padding: var(--spacing-stack-xs);
}

.table td {
    padding: var(--spacing-stratch-xs) var(--spacing-inset-md) !important;
}