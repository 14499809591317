.users-page {
    max-width: 1400px;
    margin: 0 auto;
}

#users-search {
    display: flex;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
}

#users-search .itb-button-container {
    width: 200px;
    padding-left: 0;
}

#search-button {
    position: relative;
    bottom: 4px;
}

.user-form {
    min-width: 500px;
}

.user-form form {
    max-width: 450px;
    margin: 0 auto;
}