.textButton:hover {
  cursor: pointer;
}

.updateFirmwareIcon:hover {
  cursor: pointer;
}

.updateFirmwareCard {
  min-height: 45px;
  border: var(--border-width-small) solid var(--color-neutral-light-2);
  border-radius: var(--border-radius-sm);
  padding: var(--spacing-stack-5xs) var(--spacing-inset-md);
}

.textSmall {
  font-size: var(--font-size-xs);
}

.badgesConteiner {
  min-width: 120px;
}

.textButton {
  color: var(--color-brand-primary-base);
  background: none;
  border: none;
  text-decoration: underline;
  font-size: var(--font-size-xs);
  cursor: pointer;
}
.cardItem {
  display: flex;
  font-size: var(--font-size-xs);
  min-width: 129px;
}

.cardInfos {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.changeLogShell {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-stack-3xs) 0px;
  margin-bottom: var(--spacing-stack-xs);
  border-top: 1px solid var(--color-neutral-light-3);
  max-height: 10000px;
  overflow: auto;
  transition: cubic-bezier(0, -0.11, 0, 1.35) 0.5s;
}

.changeLogShellClosed {
  height: 0;
  padding: 0;
  margin: 0;
  overflow: hidden;
  transition: cubic-bezier(0, -0.11, 0, 1.35) 0.5s;
}

.searchButton {
  min-width: 195px;
}

.cardsContainer {
  border-top: 1px solid var(--color-neutral-light-3);
}

.cardsShell {
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
}
