.innerCardRadio {
  display: flex;
  justify-content: space-between;
  color: var(--color-brand-secondary-dark);
}

.innerCardRadio > div {
  padding-right: 5%;
  width: 30%;
}
