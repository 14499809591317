#main-app {
    width: 100%;
    height: calc(100% - 92px);
    transition: padding-top 100ms ease-out;
    padding: 0;
    text-align: center;
}

#main-app.with-header-collapsed {
    padding-top: calc(var(--main-header-size-collapsed))
}
#main-app.with-header-extended {
    padding-top: calc(var(--main-header-size-extended))
}

.flex-sections-container {
    display: flex;
    flex-wrap: wrap;
    width: fit-content;
    margin: 0 auto;
    padding: 0 10px;
}

.section {
    flex: 1;
    margin: 10px;
}

.section-card {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    height: 100%;
    width: 100%;
    margin: 0 auto;
}