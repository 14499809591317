.cardContainer {
  width: 100% !important;
}

.textButton {
  color: var(--color-brand-primary-base);
  background: none;
  border: none;
  text-decoration: underline;
  font-size: var(--font-size-xs);
  cursor: pointer;
}
.cardItem {
  display: flex;
  font-size: var(--font-size-xs);
}

.cardInfos {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.changeLogShell {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-stack-3xs) 0px;
  margin-bottom: var(--spacing-stack-xs);
  border-top: 1px solid var(--color-neutral-light-3);
  max-height: 10000px;
  overflow: auto;
  transition: cubic-bezier(0, -0.11, 0, 1.35) 0.5s;
}

.changeLogShellClosed {
  height: 0;
  padding: 0;
  margin: 0;
  overflow: hidden;
  transition: cubic-bezier(0, -0.11, 0, 1.35) 0.5s;
}
