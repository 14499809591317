.radioButton {
  display: flex;
  align-items: center;
  padding: 5px;
}

.radioInput {
  appearance: none;
  width: 16px;
  height: 16px;
  background: #FFFFFF;
  border: 2px solid #000000;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 10px;
  outline: none;
  margin-left: -2px;
}

.radioInput:checked {
  background: #000000;
  border-color: #ffffff;
  outline: 2px solid #000;
}

.radioInput:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.radioLabel {
  cursor: pointer;
  color: #000 !important;
}
