.body {
  padding: var(--spacing-stack-xl) var(--spacing-stack-5xl);
  min-height: 100vh;
  background-color: var(--color-neutral-light-4);
  display: flex;
  flex-direction: column;
  text-align: left;
}

.cardBody {
  padding: var(--spacing-stack-xs) calc(var(--spacing-inline-xs) + 2px);
  flex: 1;
}

.tabsContainer ul {
  padding-inline-start: 0;
}