.body{
    background: var(--color-neutral-light-4);
    margin-top: -39px;
    min-height: calc(100% - 200px);
    z-index: 0;
    position: relative;
    padding: var(--spacing-stack-md) var(--spacing-stack-5xl);
}

.cardTable {
    min-height: calc(100vh - 435px);
}
.messageEmptyShell {
    min-height: calc(100vh - 490px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.linkToMacAndSite {
    text-decoration: underline;
    color: var(--color-neutral-dark-2);
}

.searchButton {
    min-width: 117px;
    min-height: 48.59px;
}

.radioContainer {
    gap: var(--spacing-inline-lg);
}

.statusCel div + div {
    margin-left: var(--spacing-stack-5xs);
}

.tooltipTagContainer {
    display: inline-flex;
}