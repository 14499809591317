.oplog-data-icon {
    width: 110px;
    padding-left: 10px;
    text-align: center;
    cursor: pointer;
}

.oplog-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,.8);
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.log-data-container {
    background-color: white;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.log-data pre {
    text-align: left;
}