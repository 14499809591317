.no-access-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.no-access-page svg {
    transform: rotate(180deg);
    margin: 40px auto;
}