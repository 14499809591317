.deviceName {
  color: var(--color-neutral-dark-3);
}

.deviceInfos {
  display: flex;
  align-items: center;
  gap: var(--spacing-stack-3xl);
}

.tags div + div {
  margin-left: var(--spacing-stack-5xs);
  display: inline-flex;
}

@media (max-width: 1480px) {
  .deviceInfos {
    justify-content: space-between;
    gap: var(--spacing-inline-xs);
  }
}
