#accounts-page {
    overflow-x: auto;
    width: 850px;
    margin: 0 auto;
    position: relative;
    padding-top: 60px;
}

#add-user-button {
    width: 200px;
    position: absolute;
    left: 15px;
    top: 15px;
}

#accounts-page .itb-input-field{
    position: relative;
    top: 5px;
    right: 2px;
    height: 60px;
    width: 170px;
    padding-left: 0px;
}

#accounts-page select.itb-input{
    padding: 5px;
    height: 36px;
}
#accounts-page .itb-input-field.select.without-feedback::after {
    top: 22px !important;
}

.accounts-delete {
    cursor: pointer;
}
.accounts-delete svg {
    fill: var(--text-color);
}
.accounts-delete:hover svg {
    fill: red;
}

#confirm-delete-modal {
    max-width: 480px;
    margin: 0 auto;
}
#confirm-delete-modal svg {
    display: block;
    fill: red;
    margin: 0 auto;
    padding-bottom: 40px;
    width: 100px;
    height: 100px;
}

#single-user-info {
    max-width: 800px;
    margin: 0 auto;
    text-align: left;
}

#my-account-widget {
    position: fixed;
    top: 3px;
    right: 60px;
    z-index: 2;
    cursor: pointer;
    padding: 8px 18px 4px 6px;
    border-radius: 6px;
}
#my-account-widget:hover {
    background-color: rgba(255,255,255,.2);
}
#my-account-widget svg {
    position: absolute;
    fill: white;
    bottom: 15px;
    right: 6px;
    transform: rotate(270deg);
}
#my-account-widget #my-account-picture img{
    --pic-size: 30px;
    width: var(--pic-size);
    height: var(--pic-size);
    border-radius: 50%;
    border: 2px solid white;
}

#my-account-menu {
    position: absolute;
    padding: 20px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 6px;
    width: 160px;
    right:3px;
    top: 48px;
}
#my-account-menu:after {
    content: '';
    background-color: white;
    border: solid white;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-135deg);
    position: absolute;
    top: -5px;
    right: 25px;
}

.my-account-menu-item {
    cursor: pointer;
}
.my-account-menu-item:hover {
    font-weight: bold;
}

#my-account-page {
    display: flex;
    max-width: 800px;
    margin: 0 auto;
}

.personal-info {
    flex: 1;
    padding: 20px 40px;
    margin: 20px auto;
    border: 1px solid #ddd;
    border-radius: 6px;
    width: fit-content;
    box-shadow: var(--default-shadow);
    max-width: 380px;
}
.personal-info .itb-button-container {
    padding: 10px 0 0 0;
}

#personal-info-pic {
    padding: 20px 0;
}
#personal-info-pic img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

#personal-info-data {
    width: fit-content;
    margin: 0 auto;
}


.user-info {
    padding: 10px 0;
    text-align: left;
    margin: 0 auto;
}
.user-info label{
    display: block;
    font-size: 20px;
    font-weight: bold;
}
.user-info span {
    line-height: 24px;
    overflow-wrap: anywhere;
}

.delete-account-modal {
    width: 600px;
    text-align: left;
}

.delete-account-modal ul {
    padding: 10px 50px;
}
.delete-account-modal .centered {
    text-align: center;
}

.delete-account-modal input {
    border-color: red;
}

.remotize-version {
    position: absolute;
    bottom: 5px;
    right: 5px;
    color: #aaa;
    font-size: 10px;
}

.account-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}
.account-overlay.open {
    bottom: 0;
    right: 0;
}

.user-add-modal .itb-input-field{
    height: 100px !important;
    width: 230px !important;
    padding-left: 15px !important;
}

.user-add-modal select{
    height: 60px !important;
}

.user-add-modal .select::after {
    top: 60px !important;
}
