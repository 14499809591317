@import '../libs/conecte-se/styles/core.scss';
@import './titles.scss';
@import './texts.scss';
@import './helper-classes.scss';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&family=Roboto:wght@300;400;700;900&display=swap');

@media only screen and (min-width: 96em){
  .container {
    width: 97rem;
}
}

/* scrollbar */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}