.subtitle {
  position: absolute;
  top: 0;
  margin-top: var(--spacing-stack-2xl);
}

.subtitle > span {
  font-size: var(--font-size-xs);
}

.textButton {
  color: var(--color-brand-primary-base);
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  font-size: var(--font-size-xs);
  margin-top: var(--spacing-stack-xl);
}
