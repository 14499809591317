.button {
  display: flex;
  align-items: center;
  border-radius: var(--border-radius-sm);
  border-style: solid;
  border-width: var(--border-width-small);
  font-size: var(--font-size-sm);
  text-transform: uppercase;
  color: var(--color-neutral-light-5);
  padding: var(--spacing-stack-4xs) var(--spacing-inset-3xl);
  cursor: pointer;
  transition: 0.3s all;
}

.isLoading {
  pointer-events: none;
}

.isLoadingBtnIcon {
  pointer-events: none;
  background-color: var(--color-brand-secondary-base) !important;
}

.button > span {
  display: flex;
  align-items: center;
}

.button:hover:enabled {
  box-shadow: 0px 3px 6px #00000029;
}

button[disabled] {
  background-color: var(--bg-button-disabled);
  border-color: var(--bg-button-disabled);
  color: var(--button-disabled);
  cursor: default;
}

button[disabled].ghost {
  background: transparent;
  border: none;
  color: var(--button-disabled);
  cursor: default;
}

.primary {
  background-color: var(--color-status-ok-dark);
  border-color: var(--color-status-ok-dark);
  color: var(--color-neutral-light-5);
}

.primary:hover:enabled {
  background-color: var(--color-status-ok-darkest);
  border-color: var(--color-status-ok-darkest);
}

.secondary {
  background-color: var(--color-brand-secondary-base);
  border-color: var(--color-brand-secondary-base);
}

.secondary:hover:enabled {
  background-color: var(--color-product-light);
  border-color: var(--color-product-light);
}

.secondary:focus:enabled {
  background-color: var(--color-brand-secondary-dark);
  border-color: var(--color-product-light);
}

.outline {
  background-color: transparent;
  border-color: var(--button-outline-color);
  color: var(--button-outline-color);
}

.outline:hover:enabled {
  background-color: var(--color-product-light);
  border-color: var(--color-product-light);
  color: var(--color-neutral-light-5);
  box-shadow: 0px 3px 6px #00000029;
}

.ghost {
  background-color: transparent;
  border-color: transparent;
  color: var(--button-ghost-color);
}

.ghost:hover:enabled {
  background-color: var(--color-brand-secondary-base);
  color: var(--color-neutral-light-5);
  border-color: transparent;
}

.ghostIcon {
  padding: var(--spacing-stratch-xxxs);
}

.reverse {
  flex-direction: row-reverse;
}

.icon {
  margin-right: 6px;
  margin-left: 0px;
}

.icon_right {
  margin-right: 0px;
  margin-left: 6px;
}

.icon_only {
  margin-right: 0px;
  font-size: var(--font-size-xl);
}

.text_outside .button {
  padding: var(--spacing-stratch-xxxs);
}

.text_outside {
  display: flex;
  align-items: center;
  gap: 10px;
  text-transform: uppercase;
}

.rounded {
  border-radius: var(--border-radius-circle);
}

.button_group {
  display: flex;
}

.button_group button {
  border-radius: var(--border-radius-none);
  border-width: var(--border-width-small);
  border-color: var(--color-brand-secondary-light);
}

.button_group button:first-child {
  border-top-left-radius: var(--border-radius-sm);
  border-bottom-left-radius: var(--border-radius-sm);
}

.button_group button:last-child {
  border-top-right-radius: var(--border-radius-sm);
  border-bottom-right-radius: var(--border-radius-sm);
}
