.bodyMessage {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .content {
    width: 500px;
  }
  
  .fullPage {
    height: calc(100vh - 260px);
  }
  
  .ball {
    width: 96px;
    height: 96px;
    border-radius: 50%;
    background-color: var(--color-neutral-light-3);
    color: var(--color-neutral-light-1);
  }
  
  .iconInBall {
    width: 96px;
  }
  