.shellTooltip {
  position: relative;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shellTooltipMiddle {
  display: flex;
  justify-content: center;
}

.tooltip {
  background: var(--color-brand-secondary-light);
  padding: var(--spacing-stratch-quarck) var(--spacing-inset-sm);
  opacity: 0;
  color: var(--color-neutral-light-5);
  text-align: center;
  border-radius: var(--border-radius-sm);
  position: absolute;

  z-index: 1;
  transition: opacity 0.3s;
  width: max-content;
  pointer-events: none;
}

.shellTooltip:hover .tooltip {
  opacity: 1;
}

.center {
  top: auto;
}

.middle {
  left: auto;
  right: auto;
}

.bottom {
  top: 115%;
}

.top {
  bottom: 115%;
}

.right {
  left: 110%;
}

.left {
  right: 110%;
}

.start {
  left: 0%;
}

.end {
  right: 0%;
}
